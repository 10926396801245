@import "styles/_transport";
@import "styles/_helper";
@import 'styles/_variable';
@import 'styles/_scrollbar.scss';

.bg-w {
  background-color: #ffffff;
}
// *******Flex Css*******
.flex {
  display: flex;
}

.flex-container {
  width: 100%;
  display: flex;
  // justify-content: space-between;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.center {
  justify-content: center !important;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-nogrow {
  flex-grow: 0;
}
.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-align-center {
  align-items: center;
}
.left {
  float: left;
}

.right {
  float: right;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-just {
  text-align: justify;
}
.text-center {
  text-align: center !important;
}
.align-top {
  vertical-align: top;
}
.align-bottom {
  vertical-align: bottom;
}
.align-middle {
  vertical-align: middle;
}
.align-center {
  align-items: center !important;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}

.align-baseline {
  align-items: baseline;
}
// **********Flex End************
.bgColor {
  background-color: #5182e3;
}
.bgColor-2 {
  background-color: #3ea561;
}
.pointer {
  cursor: pointer;
}

.ml-16 {
  margin-left: 16px;
}

.ml-4 {
  margin-left: 4px !important;
}
.ml-8 {
  margin-left: 8px !important;
}

.m-auto {
  margin: auto;
}
.pointer {
  cursor: pointer;
}
.p-16 {
  padding: 16px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.m-5 {
  margin: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-7 {
  margin-top: 7px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.ml-16 {
  margin-left: 16px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.ml-1 {
  margin-left: 8px !important;
}
.ml-2 {
  margin-left: 16px !important;
}
.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.m-auto {
  margin: auto !important;
}
.p-4 {
  padding: 4px !important;
}
.m-4 {
  padding: 4px !important;
}
.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mt-4 {
  margin-top: 4px;
}
.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.width-100-p {
  width: 100%;
}
.glass-effect {
  background: rgba(106, 106, 106, 0.3);
  backdrop-filter: saturate(180%) blur(10px);
}
.link {
  text-decoration: none !important;
  color: white !important;
}
.no-text-style {
  text-decoration: none !important;
  color: unset !important;
}
.MuiContainer-root {
  background-color: white;
  margin-top: 20px;
}
.text-white {
  color: white;
}
.error-color {
  color: #f44336;
}

.cust-select.MuiFormControl-root {
  width: 100%;
  .MuiFormLabel-root {
    margin-left: 16px;
  }
}
.m-a {
  margin: auto !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.text-end {
  text-align: end;
}
.ml-0 {
  margin-left: 0px !important;
}

.align-item-center {
  display: flex !important;
  align-items: center !important;
}
.p-8 {
  padding: 8px !important;
}

.text-title {
  font-size: 20px;
  font-weight: 500;
}

.cust-card {
  background-color: #fff;
  padding: 10px 8px;
  border-radius: 4px;
}
.m-4 {
  margin: 4px !important;
}
.m-8 {
  margin: 8px !important;
}
.fab-call {
  margin: 0px !important;
  top: auto !important;
  right: 20px !important;
  bottom: 20px !important;
  left: auto !important;
  position: fixed !important;
  z-index: 10;
}
.fab-fav {
  margin: 0px !important;
  top: auto !important;
  right: 20px !important;
  bottom: 80px !important;
  left: auto !important;
  position: fixed !important;
  z-index: 10;
}
.no-decor {
  text-decoration: none !important;
}
.pt-10 {
  padding-top: 10px !important;
}

// ************ card Css Location *****************
.main-sd {
  padding: 8px;
  .mtb {
    margin: 8px 0px 14px 0px;
  }

  .main-title {
    font-size: 12px;
    font-weight: 600;
    color: grey;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 500;
    color: #3a3a3a;
    place-self: center;
    margin-right: 16px;
    display: inline-block;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .cust-icon {
    font-size: 21px;
    margin: 2px;
  }
  .rel {
    position: relative;
    top: -6px;
    left: 8px;
  }
}

.m-all {
  margin: 2px;
}
//************** end card Css Location ************
.dashed-hr {
  border-bottom: 1px dashed silver;
  padding: 4px 0px;
}

.c1 {
  position: relative;
  z-index: 2;
  .color {
    background-color: rgb(131, 75, 255);
  }
  .img {
    background-image: url("../assets/images/car/bg-car.png");
    position: absolute;
    background-size: 100%;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
.c2 {
  background-color: #ff8a80;
}
.c3 {
  background-color: #64b5f6;
}
.c11 {
  position: relative;
  .color {
    background-color: rgb(245, 0, 87);
  }
  .img {
    background-image: url("../assets/images/camera/bg-camera.jpg");
    position: absolute;
    background-size: 100%;
    height: 100%;
    width: 100%;
    opacity: 0.1;
  }
}

.c13 {
  position: relative;
  .color {
    background-color: rgb(255, 151, 14);
  }
  .img {
    background-image: url("../assets/images/camera/bg-camera.jpg");
    position: absolute;
    background-size: 100%;
    height: 100%;
    width: 100%;
    opacity: 0.1;
  }
}
.gridBGC {
  background-color: #f5f5f5;
}
.pr-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

//Vehicle Boking Card list
.top-book-lebel {
  margin-bottom: -24px;
  text-align: end;
  font-size: 13px;
  font-weight: 500;
  span {
    position: relative;
    top: -8px;
    right: -8px;
    background-color: $pink-color;
    color: #fff;
    padding: 4px 4px;
    width: 75px;
    text-align: center;
    display: inline-block;
    border-radius: 0px 0px 0px 6px;
  }
}
//Vehicle Boking Card list end

input[type="text"].lb-input {
  width: 100%;
  padding: 8px 4px;
  margin: 8px 0;
  box-sizing: border-box;
}

.error-msg {
  margin: 4px 0px;
  width: 100%;
}
.title-case {
  text-transform: capitalize !important;
}
.relative {
  position: relative;
}

.login-btn {
  border-radius: 4px;
  transition: all 0.3s;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  margin-right: 10px;
}

.gog-btn {
  background-color: #fff;
}
.gog-btn:hover {
  background-color: #f5f5f5;
}

.fb-btn {
  background-color: #0977e7;
}
.fb-btn:hover {
  background-color: #5182e3;
}
.phone-btn {
  background-color: #3a3a3a;
}

//Head titles
.h1-title {
  font-size: 20px;
  font-weight: 600;
  padding: 4px 0px 8px 0px;
}
.font-black {
  color: #1d1d1d !important;
}

.primary-border {
  border: $primary-color solid 1px !important;
}
.primary-color{
  color:$primary-color;
}
