
.aligncenter {
    text-align: center;
}

#blogview {
    max-width: 800px;
    margin: auto;

    img {
        inline-size:fit-content;
        height: auto;
        max-width: 800px;
    }

    .content {
        font-size: 1rem;
        line-height: 26px;
        margin: 15px 8px 15px 8px;
        text-align: justify;
    }
}
.title {
    margin-top: 10px;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 10px;
    font-size: calc(1.0rem + 1vw);
    font-weight: 500;
}
