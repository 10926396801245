*{
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
}
.topfilter{
  display: flex;
  margin-top: 12px;
  justify-content: center;
}
.sortresult{
  display: inline-block;
  text-align: center;
   width: 180px;
  position: relative;
  background-color: rgb(183 178 178);
 
}
.sortresult a{
  text-decoration: none;
  color: black;
 
}
.sortresult>.icon{
  position: relative;
  top: 8px;
}
.listitem {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap; 
  align-items: center;
  
}
.listitems{
  border: 1px solid rgb(158, 151, 151);
  line-height: 0px;
  width: 11.6vw;
  background-color: white;
}
.icon{
  cursor: pointer;
  margin-left: 10px;
}
  
