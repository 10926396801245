.graph {
  height: 300px;
  width: 300px;
}
.container {
  display: flex;
  flex-direction: row;
}
.Card {
  height: auto;
  width: 70vw;
  background-color: rgb(236, 246, 247);
  /* display: flex; */
  margin: auto;
}
.box2 {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255);
  padding: 9px 9px;
  text-align: center;
  margin: 8px;
  /* transition: width 2s;
 cursor: pointer; */
}
/* .box2:hover{
    color:blue;
    width: 350px;
    height: 350px;
    background-color: #dbdff6;
} */
.box2:active {
  color: blue;
}
.box3 {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255);
  padding: 9px 9px;
  text-align: center;
  margin: 8px;
}
.box4 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 52px; */
}
.box1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Bargraph {
  width: 26vw;
  background-color: white;
}

button {
  /* margin-left: 120px; */
  margin-top: 9px;
  background-color: #3f51b5;
  color: white;
  padding: 8px 11px;
  border-radius: 4px;
  outline: none;
  border: none;
  text-transform: capitalize;
  cursor: pointer;
}
ul {
  list-style-type: none;
}
