.menu-body {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text-align {
    margin-left: 16px;
    font-weight: 500;
    color: #030303;
  }
  .icon-css {
    background-color: #ffcfe0;
    border-radius: 4px;
    padding: 4px;
    height: 31px;
    width: 31px;
    color: #f50057;
  }
  .align-item{
    display: flex;
    align-items: center;
  }
  .flex-column{
    flex-direction: column;
  }

}
.css-1k9q6fl {
  position: relative;
  outline: 0;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 10px;
  background-color: #E7EBF0;
}
