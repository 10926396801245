.book-container {
  position: absolute;
  width: 100%;
  z-index: 10;
  color: #fff;

  // Extra-Large Device
  @media (min-width: 1500px) {
    top: 200px;
  }

  // Laptop/Desktop
  @media (min-width: 600px) and (max-width: 1499px) {
    top: 170px;
  }

  // Mobile
  @media (max-width: 600px) {
    top: 65px;
  }

  .book-title {
    font-weight: 500;
    font-size: calc(1.275rem + 0.3vw);
  }

  .book-sub-title {
    font-size: 14px;
    margin-bottom: 14px;
    margin-top: 4px;
  }

  .book-btn {
    display: inline;
    border-radius: 5px;
    padding: 8px;
    font-size: 0.8rem;
  }
}

//New Landing page
.slide-img {
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 6px;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .overlay {
    background-color: #00000052;
    width: 100%;
    border-radius: 4px;

    // Extra-Large Device
    @media (min-width: 1500px) {
      height: 40%;
      padding-top: 20px;
    }

    // Laptop/Desktop
    @media (min-width: 600px) and (max-width: 1499px) {
      height: 40%;
      padding-top: 20px;
    }

    // Mobile
    @media (max-width: 600px) {
      height: 60%;
      padding-top: 15px;
    }
  }

  // Laptop/Desktop
  @media (min-width: 1500px) {
    height: 350px;
  }

  // Laptop/Desktop
  @media (min-width: 600px) and (max-width: 1499px) {
    height: 300px;
  }

  // Mobile
  @media (max-width: 600px) {
    height: 170px;
  }
}

.category {
  flex-grow: 1;
  width: 100%;

  .paper {
    padding: 12px 4px;
    text-align: center;
    height: 84px;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 20%),
      1px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .category-btn {
    display: block;
    font-weight: 500;
    line-height: 1;
    padding-top: 2px;
    color: #535455;
    font-size: 0.8rem;
  }

  .category-icon {
    font-size: 2.5rem;
  }

  .soon {
    position: absolute;
    background-color: #ea4c62;
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 3px;
    top: 2px;
    left: 2px;
    border-radius: 4px;
  }
}

ul li {
  padding: 0px 3px 0px 3px !important;
  margin:8px
}

.mt-1 {
  margin-top: 8px;
}

.offer {
  padding: 1rem;
  margin: 0px 0px;
  border-radius: 5px;

  .offer-text {
    padding: 0px 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    margin: 12px 0px;
    justify-content: center;
    font-size: 1.5em;

    strong {
      padding: 3px;
      border: 1px solid #323232;
      line-height: 30px;
      border-radius: 4px;
    }
  }

  .pr {
    padding-right: 1rem;
    border-right: 1px solid #323232;
    min-width: 120px;
    font-weight: 700;
    font-size: calc(1.2rem + 0.2vw);
  }

  .pl {
    padding-left: 1rem;
    font-size: 14px;
  }

  .text-white {
    color: #323232;
  }
}

.anim {
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #aef4af;
  }
  to {
    box-shadow: 0 0 10px 10px #aef4af;
  }
}
