.cust-notification {
  strong {
    font-size: 13px;
    color: #3c3c3c;
  }
  div {
    font-size: 11px;
    color: #9b9b9b;
    width: 215px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mr-8 {
  margin-right: 8px;
}

.no-notify {
  text-align: center;
  .no-icon {
    font-size: 7rem;
    color: #a3a3a3;
  }
  div {
    font-size: 17px;
    font-weight: 500;
    color: #343434;
  }
}