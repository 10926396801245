.MuiToolbar-gutters {
    padding-left:"0px" !important;
}

.MuiButton-startIcon {
    margin-right: 2px !important;
}
.color-white{
    color: #fff !important;
}
.menu-btn{
    position: relative;
    top: 7px;
}
.lang-icon{
    margin-right: 6px;
}