@import 'styles/_variable';

// *******UserDetailsCard*******
.btn-deal{
    background-color: $green-color;
    margin-left: 16px;
}

.content-border{
    // border: 2px solid green;
    text-align:left;
    border-radius: 4px;
    padding: 12px;
    margin: 10px 16px;
    background-color: rgb(233, 255, 239);
    border-color: #008009;
}
@media screen {
    .content-border{
        overflow: hidden;
    }
}

//************SortLinks******************
.SortLinks{
    display: flex;
    place-content: center space-around;
    align-items: center;
    margin-top: -40px;
    background-color: #4caf501a;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 20px;
    padding: 16px;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 0 10px;
    .pointer{
        .card-block {    
            color: #fff;
            padding: 6px 8px;
            border-radius: 4px;
            // border: 2px solid #dcdcdc;
            background-color: #3f51b5;
            width: 40px;
            margin: auto;
            &:hover{
                background-color: #f3fcff;
                color: #3f51b5;
                border: 2px solid #3f51b5;
                padding: 4px 6px;
            }
        }
        p{
            margin: 0px;
        }
    }


}

//************Specification**************
.cardItem{
    display: flex;
    padding: 8px 8px 16px;
    min-height: 415px;
    margin-right: 15px;
}




//*********Comment Section************
.comment-name{
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  line-height: 34px;
}
.comment-section{
    display: flex;
}
.comment-icon{
  font-size: 18px;
  font-weight: 600;
  color: #828282;
  gap: 4px;
  cursor: pointer;
}

.comment-time{
  font-size: 16px;
  color: silver;
}



