$digit: 2;

.overlay-block {
  position: relative;
  .overlay {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    border-radius: 6px;
  }
}

//***** Page Header *****
// moved css to style.css
//*****Helper css for Margin/ Padding*****
@mixin m-x {
  @for $i from 1 through 12 {
    .m-#{$i} {
      margin: #{$i * $digit}px !important;
    }
    .mx-#{$i} {
      margin-left: #{$i * $digit}px !important;
      margin-right: #{$i * $digit}px !important;
    }
    .my-#{$i} {
      margin-top: #{$i * $digit}px !important;
      margin-bottom: #{$i * $digit}px !important;
    }
    .ml-#{$i} {
      margin-left: #{$i * $digit}px !important;
    }
    .mr-#{$i} {
      margin-right: #{$i * $digit}px !important;
    }
    .mt-#{$i} {
      margin-top: #{$i * $digit}px !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i * $digit}px !important;
    }
  }
}
@include m-x;

@mixin p-x {
  @for $i from 1 through 12 {
    .p-#{$i} {
      padding: #{$i * $digit}px !important;
    }
    .px-#{$i} {
      padding-left: #{$i * $digit}px !important;
      padding-right: #{$i * $digit}px !important;
    }
    .py-#{$i} {
      padding-top: #{$i * $digit}px !important;
      padding-bottom: #{$i * $digit}px !important;
    }
    .pl-#{$i} {
      margin-left: #{$i * $digit}px !important;
    }
    .pr-#{$i} {
      padding-right: #{$i * $digit}px !important;
    }
    .pt-#{$i} {
      padding-top: #{$i * $digit}px !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i * $digit}px !important;
    }
  }
}
@include p-x;

.warning-text {
  color: #ff0000;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.h-full{
  height: 100%;
}
.w-full{
  width: 100%;
}


.fonts-1 {
  font-family: "Pinyon Script", cursive;
  font-weight: 400;
  font-style: normal;
}

.fonts-2 {
  font-family: "Mr Dafoe", cursive;
  font-weight: 400;
  font-style: normal;
}

.fonts-3 {
  font-family: "Monsieur La Doulaise", cursive;
  font-weight: 400;
  font-style: normal;
}

.fonts-4 {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}

.fonts-5 {
  font-family: "Lovers Quarrel", cursive;
  font-weight: 400;
  font-style: normal;
}

.fonts-6 {
  font-family: "Alex Brush", cursive;
  font-weight: 400;
  font-style: normal;
}