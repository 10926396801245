@import 'styles/_variable';

.customeTab {
  .MuiTabs-indicator {
    background-color: #ffffff;
  }


  .MuiTabs-flexContainer {
    border: $primary-color solid 1px !important;
    border-radius: 10px 10px 0px 0px;
  }

  .MuiTab-root {
    min-height: 38px;
    padding: 0;
  }

  .MuiTab-wrapper {
    span {
      font-weight: 500;
    }
  }
}

.head-value {
  font-size: 1rem;
  font-weight: 500;
}

.head-detail {
  padding-left: 30px;
}

.ser-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.mx8y16 {
  margin: 8px 16px;
}

.flex-w {
  display: flex;
  flex-wrap: wrap;
}

.filling-empty-space-childs {
  width: 282.8px;
  height: 0;
  margin: 0 !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.item {
  padding: 4px;
  margin: 5px;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}
