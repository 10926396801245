.norm-select.MuiFormControl-root {
  width: 100%;
  margin-bottom: 8px;
  .MuiFormLabel-root {
    margin-left: 16px;
  }
  #s_region {
    top: -8px;
    left: -6px;
  }
}
