.label {
  position: absolute;
  bottom: 7px;
  left: 12px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  z-index: 10;

}

.text-v {
  position: absolute;
  bottom: 7px;
  left: 3px;
  right: 3px;
}

.text-overlay {
  background-color: #494949;
  height: 36px;
  border-radius: 0 0 8px 8px;
  opacity: 0.7;
}


.mySwiper {
  padding: 0px 4px;
}

//******View Slider **********

#slider-main-box {
  position: fixed;
  top: -6px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .viewSlider {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0 0;
  }

  .viewSlider .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .viewSlider .swiper-slide-thumb-active {
    opacity: 1;
  }

  .bottom-slide-card {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slider-box {
    position: relative;
    margin: 20px;
    height: 96vh;
    background-color: #fff;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #cbcbcb;
  }

  .slider-overlay {
    background-color: #000000;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.7;
    z-index: -1;
  }

  .slider-close {
    position: absolute;
    right: -12px;
    top: -12px;
    background-color: #fff;
    height: 25px;
    width: 25px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #cbcbcb;
    cursor: pointer;
    font-weight: 600;
    color: grey;
  }
}

//******View Slider **********
.blod-slide {
  width: 100%;
  padding: 4px;
}

.frame-size {
  position: relative;
  width: '333px';
  height: '250px';

  .play-btn {
    position: absolute;
    left: 40%;
    top: 37%;
    height: 65px;
    width: 65px;
    cursor: pointer;
  }

  .img-size {
    width: 100%
  }
}