.layer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
    opacity: 0;
}

.layer:hover {
    opacity: 0.5;
}

.icons {
    position: absolute;
    top: 50%;
    left: 46%;
    z-index: 2;

    .icon-color {
        font-size: 3rem;
        color: #7058e7;
        cursor: pointer;
    }
}

.radio-sel {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 2;
}

.img-temp {
    background-size: 100% 100%;
    height: 71vh;
}

.preview-temp {
    background-size: 100% 100%;
    height: 74vh;
    width: 34vw;
}

.font-size-2rem {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 20px;
}

.main {
    font-size: 1.5rem;
    font-weight: 700;
    color: #db0303;
}

.sub {
    font-size: 0.8rem;
    font-weight: 500;
}

.date {
    margin: 6px;
    font-weight: 700;
    font-size: 1rem;
}

.invite {
    line-height: 22px;
    margin-bottom: 8px;
    width: 240px;
}

.devotional {
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 1.5rem;
    font-weight: 700;
    width: 240px;
    line-height: 16px;
}

.sticker {
    height: 60px;
    margin-top: -30px;
    outline: 3px solid #ffb01f;
    display: block;
    background: white;
    border-radius: 100%;
}

.on {
    font-size: 2rem;
    line-height: 18px;
}
