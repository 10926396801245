.profile-head {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  
  .profile-title {
    padding: 14px 0px;
  }
  .profile-img {
    position: relative;
    margin-bottom: 8px;
    border-radius: 50%;
    border: 2px solid #3f51b5;
    background:white;
    .ico-badge {
      position: absolute;
      top: 0px;
      right: 0px;
      span{
        background-color: #ffcfe0;
        border-radius: 50%;
        padding: 4px;
        height: 27px;
        width: 27px;
        font-size: 20px;
        color: #f50057;;
      }
    }
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .name-title {
    font-size: 19px;
    text-transform: capitalize;
  }
  .email-title {
    font-size: 14px;
    color: #8b8b8b;
    margin-top: 4px;
  }
}
