.MuiToolbar-gutters {
    padding-left:"0px" !important;
}
.MuiButton-startIcon {
    margin-right: 2px !important;
}
.menu-hover:hover{
    background-color: rgb(68,76,248) !important;
    .MuiListItemText-root{
        span{
            font-weight: bold !important;
        }
    }
    .MuiListItemIcon-root{
        color: #fff;
    }
}
.menu-hover{
    div{
        color: #fff !important;
    }
}

.ml-72{
    margin-left:72px !important
}
.mt-35{
    margin-top:35px !important;
}
.mt-5{
    margin-top:5px !important;
}
.iconCenter{
    svg{
        display: flex;
        margin: auto;
        height: 120px;
        font-size: 2rem;
        color: gray;
        cursor: pointer;
    }
}


//**********Lodaer Css**************
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 0.4;
    }
}
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 0.4;
    }
}

.loader{
    width: 210px;
    height: 65px;
    -moz-transition:all 0.5s ease-in-out;
    -webkit-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
    -moz-animation:blink normal 1.5s infinite ease-in-out;
    -webkit-animation:blink normal 1.5s infinite ease-in-out;
    -ms-animation:blink normal 1.5s infinite ease-in-out;
    animation:blink normal 1.5s infinite ease-in-out;
}
//**********Lodaer Css**************